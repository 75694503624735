import Script from "next/script"

import { HubspotProvider } from 'next-hubspot'

import { gql } from "@apollo/client"
import craftApolloClient from "./api/apollo"

import { Header } from '../fuselage/global/header/header'
import { Footer } from '../fuselage/global/footer/footer'

import '../css/index.css'

function App({ Component, pageProps, navNodes, footer }) {

	// console.log('navNodes:', navNodes)
	// console.log('footer:', footer)

	return (
		<HubspotProvider>
			<Script id="google-tag-manager" strategy="afterInteractive">
				{`
					(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
					new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
					j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
					'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
					})(window,document,'script','dataLayer','GTM-PTBGJKT');
				`}
			</Script>

			<Header mainMenu={navNodes} />
			<Component {...pageProps} />
			<Footer 
				socialConnections={footer[2].socialConnections} 
				contactInfo={footer[2].contactDetails.length ? footer[2].contactDetails[0].body : null}
				hubspotForm={footer[2].hubspotForm.length ? footer[2].hubspotForm[0] : null}
			/>
		</HubspotProvider>
	)
}

export default App


App.getInitialProps = async () => {

	const { data } = await craftApolloClient().query({
		query: gql`
			query Globals {
				nodes(site: "${process.env.SITE_HANDLE}", navHandle: "${process.env.SITE_HANDLE}MainMenu", level: 1) {
					id
					title
					url
					level
					newWindow
					children {
						id
						title
						url
						level
						newWindow
					}
				}
				globalSets(site: "${process.env.SITE_HANDLE}") {
					... on footer_GlobalSet {
						id
						name
						socialConnections {
							... on socialConnections_BlockType {
								id
								hyperlink
								icon
								label
							}
						}
						contactDetails {
							... on contactDetails_BlockType {
								id
								body
							}
						}
						hubspotForm {
							... on hubspotForm_BlockType {
								id
								formId
								portalId
							}
						}
					}
				}
			}
		
		`
	})

	return { 
		navNodes: data.nodes,
		footer: data.globalSets
	}

}